import { Dispatch, SetStateAction, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { DialogClose } from 'src/components/ui/dialog'
import { IOrder } from 'src/_models/order.model'
import OrderDocumentStatus from 'src/components/order/document-status'
import OrderSignatureGuaranteeView from 'src/components/order/signature-guarantee-view'
import OrderSignatureGuarantee from 'src/components/order/signature-guarantee'
import TotalPayableAmount from 'src/components/order/total-payable-amount'
import { Input } from 'src/components/ui/input'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import currencyFormatter from 'src/components/ui/currency-formatter'
import { useTranslation } from 'react-i18next'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog'

const SupplierOrderShippingDocument = ({
  goNext,
  order,
  setShowOrderStatusModal,
}: {
  order: IOrder
  goNext: () => void
  setShowOrderStatusModal: Dispatch<SetStateAction<boolean>>
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('translation')
  const [shippingCost, setShippingCost] = useState<number | null>(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const supplierOrderShippingFeesMutation = useMutation({
    mutationKey: ['supplierOrderShippingFeesMutation'],
    mutationFn: ({ shippingCost, orderId }: { shippingCost: number; orderId: number }) => {
      const token = localStorage.getItem('token')

      return axios.post(
        `/api/supplier/order-shipping-fees/${orderId}`,
        {
          shippingCost,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const supplierOrderShippingDoneMutation = useMutation({
    mutationKey: ['supplierOrderShippingFeesMutation'],
    mutationFn: ({ orderId }: { orderId: number }) => {
      const token = localStorage.getItem('token')

      return axios.post(
        `/api/supplier/order-shipping-done/${orderId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const addShippingFess = () => {
    supplierOrderShippingFeesMutation.mutate(
      {
        orderId: order.id,
        shippingCost: shippingCost!,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Added shipping.',
          })
          queryClient.invalidateQueries()
        },
        onError: (error) => {
          const axiosError = error as AxiosError<{ message: string; redirectUrl?: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(axiosError.response?.data?.message || 'Shipment setting failed.'),
          })
        },
      },
    )
  }

  const supplierOrderShippingDone = () => {
    supplierOrderShippingDoneMutation.mutate(
      {
        orderId: order.id,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Shipping done.',
          })
          queryClient.invalidateQueries()
        },
        onError: () => {
          toast({
            variant: 'destructive',
            title: 'Shipping failed.',
          })
        },
      },
    )
  }

  const shippingNotAvailable = order.shippingCost === null || order.shippingCost === undefined || order.shippingCost < 0

  return (
    <>
      <div className="flex max-h-[calc(90vh-514px)] flex-col overflow-y-scroll px-[30px]">
        <OrderDocumentStatus order={order} />
        {shippingNotAvailable && (
          <div className="flex flex-row items-center justify-between pl-[28px]">
            <div className="flex gap-[6px] text-sm font-normal 2xl:text-base">Shipping Amount</div>
            <div className="flex flex-row gap-2.5">
              <>
                <Input
                  value={shippingCost === null || shippingCost === undefined ? '' : shippingCost}
                  onChange={(e) => {
                    if (e.target.value === null || e.target.value === undefined) {
                      return
                    }
                    console.log('value', typeof e.target.value, e.target.value)
                    setShippingCost(+e.target.value)
                  }}
                  type="number"
                  className="w-[115px] py-2.5 text-right"
                />
                <Button
                  onClick={addShippingFess}
                  disabled={
                    supplierOrderShippingFeesMutation.isLoading || shippingCost === null || shippingCost === undefined
                  }
                  size="icon"
                  className="rounded-[10px] px-[13px]"
                >
                  <img src="/check.svg" width="20px" />
                </Button>
              </>
            </div>
          </div>
        )}
      </div>
      <div className="mt-5 h-[136px] px-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <TotalPayableAmount order={order} />
        <div className="flex gap-5">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            disabled={
              shippingNotAvailable || order.status !== 'SHIPPING_PAID' || supplierOrderShippingDoneMutation.isLoading
            }
            onClick={() => setShowConfirmModal((c) => !c)}
          >
            Shipment Sent
          </Button>
        </div>
      </div>
      <AlertDialog open={showConfirmModal} onOpenChange={(open) => setShowConfirmModal(open)}>
        <AlertDialogContent className="rounded-[40px]">
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              Have you set up your payment information on Paykeeper? If not, please do so to ensure shipment and product
              payouts.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => order.paykeeperSupplierLink && window.open(order.paykeeperSupplierLink, '_self')}
            >
              No
            </AlertDialogCancel>
            <AlertDialogAction onClick={supplierOrderShippingDone}>Yes</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default SupplierOrderShippingDocument
