import CreateAuction from 'src/pages/supplier/fuel-trading/create-auction'
import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IAuction } from 'src/_models/auction.model'
import AuctionCard from 'src/components/auction/auction-card'
import 'swiper/css/autoplay'
import { Autoplay } from 'swiper/modules'
import { useUserStore } from 'src/_store/user.store'
export default function FuelTradingMyAuctions() {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  const myAuctionQuery = useQuery({
    queryKey: ['supplier-my-auctions', 'auctions', user?.fuelTradingStatePreference],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get('/api/supplier/auctions?state=' + user?.fuelTradingStatePreference, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<AxiosResponse<{ auctions: IAuction[] }>>
    },
    enabled: !!user,
  })

  return (
    <section className="mt-[30px] w-full 2xl:mt-[38px]">
      <div className="container mx-auto bg-transparent ">
        <div className="mb-[15px] flex w-full justify-between">
          <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">My Auctions</h2>
          <a href="/supplier/my-auctions" className="text-sm font-bold text-primary">
            View All
          </a>
        </div>

        <div className="flex items-center gap-5 align-middle">
          <CreateAuction className="xl:pt-2 2xl:pt-1" />
          {myAuctionQuery.data && (
            <>
              {myAuctionQuery.data && myAuctionQuery.data.data.auctions.length === 1 && (
                <div className="flex flex-row gap-5 pt-5">
                  {myAuctionQuery.data.data.auctions.map((auction) => {
                    return <AuctionCard key={`my-auction-${auction.id}`} auction={auction} />
                  })}
                </div>
              )}
              {myAuctionQuery.data && myAuctionQuery.data.data.auctions.length > 1 && (
                <div className="flex flex-row gap-5">
                  <Swiper
                    // modules={[Pagination]}
                    modules={[Autoplay]}
                    loop={myAuctionQuery.data.data.auctions.length >= 4}
                    spaceBetween={20}
                    slidesPerView={4}
                    pagination={{
                      clickable: false,
                    }}
                    grabCursor={true}
                    autoplay={{
                      delay: 2000,
                      stopOnLastSlide: false,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                      // when window width is <= 320px
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                      },
                      // when window width is <= 480px
                      425: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      // when window width is <= 640px
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      1440: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      1920: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                    className="carousel relative grid grid-cols-4 gap-5"
                  >
                    {myAuctionQuery.data.data.auctions.map((auction) => {
                      return (
                        // <div className="grid grid-cols-4 gap-5"></div>

                        <SwiperSlide key={auction.id} className="relative mt-6">
                          <AuctionCard className="mb-[20px]" auction={auction} />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              )}
            </>
          )}
        </div>

        {/* CASE 1 --- Seller Name and User are the same start */}
        {/* <div className="w-full rounded-[40px] bg-white p-[47.5px]">
          <div className="flex items-center justify-between">
            <div className="text-xl font-bold text-secoundary">Auction Type</div>
            <div className="text-xl font-bold text-darkred">Ends in 3 Days </div>
          </div>
          <hr className="my-5 h-px border-0 bg-[#E0E0E0]"></hr>
          <div className="flex w-full justify-between gap-5">
            <div className="min-h-[160px] w-[200px] rounded-[20px] bg-[#E0E0E0]"></div>
            <div className="flex w-full flex-col gap-[10px]">
              <div className="flex items-center gap-[10px] align-middle text-2xl font-bold text-secoundary">
                Grey Ammonia
                <span className="rounded-[9.46px] bg-gray2 px-[10px] py-[5px] text-2xl font-bold text-primary">
                  99%
                </span>
              </div>
              <div className="text-xl font-normal text-secoundary">by Methanol Purification Co.</div>
              <div className="flex gap-[10px]">
                <div className="h-[28px] w-[21px]">
                  <img src="location.svg" alt="location" />
                </div>
                <div className="text-xl font-normal text-secoundary">State and location</div>
              </div>
            </div>
          </div>
        </div> */}

        {/* CASE 1 --- Seller Name and User are the same end */}
      </div>
    </section>
  )
}
