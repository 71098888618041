import { useUserStore } from 'src/_store/user.store'
import React, { useContext, useEffect, useState } from 'react'
import { IOrder } from 'src/_models/order.model'
import { WallyMessaging } from 'src/types/types'
import { cn } from 'src/lib/utils'
import { ChevronDown, ChevronUp, XIcon } from 'lucide-react'
import { CometChat, Group } from '@cometchat/chat-sdk-javascript'
import {
  CometChatThemeContext,
  CometChatMessageComposerAction,
  CometChatDetailsTemplate,
  CometChatMessages,
  DetailsConfiguration,
  MessageListConfiguration,
  CometChatMessageList,
  CometChatUIKit,
  ComposerId,
  CometChatTheme,
  CometChatMessageComposer,
  MessageComposerConfiguration,
  MessageHeaderConfiguration,
  CometChatDetailsOption,
  DetailsUtils,
  MessagesStyle,
} from '@cometchat/chat-uikit-react'

const initialMessages: WallyMessaging[] = [
  {
    timestamp: '10:00 AM',
    user_whosend: 'Wally',
    message: 'Hi, I am Wally, ask me anything',
  },
  // more messages...
]

const ChatAuction = ({
  order,
  cometChatGroupId,
  toogleChat,
}: {
  order: IOrder
  cometChatGroupId: string
  toogleChat: () => void
}) => {
  const [group, setGroup] = useState<Group>()
  let { theme } = useContext(CometChatThemeContext)

  // theme.palette.setBackground({ light: '#0077b6', dark: '#0077b6' })
  theme.palette.setPrimary({ light: '#0077b6', dark: '#0077b6' })
  // To change the background color for the message text box
  theme.palette.setAccent50({ light: '#e0e0e0', dark: '#e0e0e0' })
  // To change the icon color
  theme.palette.setAccent500({ light: '#0077b6', dark: '#0077b6' })
  // To change the background color for the avatar on message box
  theme.palette.setAccent700({ light: '#0077b6', dark: '#0077b6' })

  const messagesStyle = new MessagesStyle({
    // background: '#0077b6',
    border: '3px solid #0077b6',
    borderRadius: '20px',
    // height: '100vh',
  })

  const getMenu = () => {
    const handleReload = () => {
      window.location.reload()
    }
    const getButtonStyle = () => {
      return {
        height: '20px',
        width: '20px',
        border: 'none',
        borderRadius: '0',
        background: 'transparent',
        buttonIconTint: '#7E57C2',
      }
    }
    return (
      <div style={{ marginRight: '20px' }}>
        <cometchat-button
          iconURL="custom icon url"
          buttonStyle={JSON.stringify(getButtonStyle())}
          onClick={handleReload}
        >
          {' '}
        </cometchat-button>
      </div>
    )
  }

  const themeCometChat = new CometChatTheme({})
  const messageComposerConfiguration = new MessageComposerConfiguration({
    attachmentOptions: (_: CometChat.Group | CometChat.User, composerID: ComposerId) => {
      const defaultOptions: CometChatMessageComposerAction[] | undefined =
        CometChatUIKit.getDataSource().getAttachmentOptions(themeCometChat, composerID)
      const filteredOptions = defaultOptions.filter((ele) => ele.id !== 'image' && ele.id !== 'video')
      return filteredOptions
    },
  })

  const messageListConfiguration = new MessageListConfiguration({
    loadingStateView: <img src="https://loading.io/assets/mod/spinner/spinner/lg.gif" />, // this is an example, please render your own loading view here
  })

  // const messageHeaderConfiguration = new MessageHeaderConfiguration({
  //   menu: getMenu(),
  // })

  const getTemplateOptions = (
    template: CometChatDetailsTemplate,
    userOrGroup: CometChat.User | CometChat.Group,
  ): CometChatDetailsOption[] | undefined => {
    const { id } = template
    if (id !== undefined) {
      return userOrGroup instanceof CometChat.User
        ? template.options?.(userOrGroup, null, id)
        : template.options?.(null, userOrGroup, id)
    }
    return undefined
  }

  const detailsConfiguration = new DetailsConfiguration({
    data: (user?: CometChat.User, group?: CometChat.Group) => {
      let detailsTemp = CometChatUIKit.getDataSource().getDefaultDetailsTemplate(
        user as any,
        null,
        group as any,
        new CometChatTheme({}),
      )
      detailsTemp.forEach((template) => {
        template.itemSeparatorColor = 'transparent'
        let templateOptions: CometChatDetailsOption[] | undefined = getTemplateOptions(template, (user || group) as any)
        templateOptions?.forEach((opt: CometChatDetailsOption) => {
          if (opt.id === 'viewMembers') {
            opt.titleColor = '#0077B6'
          }
        })
        if (templateOptions) {
          template.options = (loggedInUser: CometChat.User | null, group: CometChat.Group | null, section: string) => {
            return templateOptions
          }
        }
      })
      // console.log(detailsTemp[1].options, DetailsUtils.getDefaultGroupMemberOptions)
      detailsTemp[1].options = () => {
        return [
          new CometChatDetailsOption({
            title: 'Ask REgen for help',
            titleColor: '#0077B6',
            titleFont: '600 15px sans-serif,Inter',
            onClick: () => {
              alert('Custom Option')
            },
          }),
        ]
      }
      detailsTemp[1].title = ''
      return detailsTemp
    },
  })

  useEffect(() => {
    CometChat.getGroup(cometChatGroupId).then((groupLocal) => {
      setGroup(groupLocal)
    })
  }, [])

  return (
    <>
      <div
        style={{ boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);' }}
        className={cn(
          'border-primary fixed top-0 -right-[calc(50vw-370.5px)] mr-4 z-50 bg-white rounded-[20px] w-[440px] border-[3.1px] h-[434px] overflow-hidden',
          // minimised && 'h-20',
        )}
      >
        <span className="cursor-pointer absolute top-6 right-12 z-50 text-primary" onClick={toogleChat}>
          <XIcon />
        </span>
        <CometChatThemeContext.Provider value={{ theme }}>
          {group && (
            <CometChatMessages
              group={group}
              messagesStyle={messagesStyle}
              // messageHeaderConfiguration={messageHeaderConfiguration}
              detailsConfiguration={detailsConfiguration}
              messageComposerConfiguration={messageComposerConfiguration}
              messageListConfiguration={messageListConfiguration}
            />
          )}
        </CometChatThemeContext.Provider>
        {/*{group && <CometChatMessageList group={group} />}*/}
        {/*{group && (*/}
        {/*  <CometChatMessageComposer*/}
        {/*    group={group}*/}
        {/*    attachmentOptions={(userOrGroup, composerId) => {*/}
        {/*      const defaultOptions: CometChatMessageComposerAction[] | undefined =*/}
        {/*        CometChatUIKit.getDataSource().getAttachmentOptions(theme, composerId)*/}
        {/*      const filteredOptions = defaultOptions.filter((ele) => ele.id !== 'image' && ele.id !== 'video')*/}
        {/*      return filteredOptions*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        {/*<div className="flex flex-row justify-between pb-6">*/}
        {/*  <h2 className="font-bold text-2xl tracking-tight">{order.auction.generatedId}</h2>*/}
        {/*  <span className="flex flex-row gap-2">*/}
        {/*    <span className="p-2 cursor-pointer" onClick={() => setMinimised(!minimised)}>*/}
        {/*      {minimised ? <ChevronUp /> : <ChevronDown />}*/}
        {/*    </span>*/}
        {/*    <span className="p-2 cursor-pointer" onClick={toogleChat}>*/}
        {/*      <XIcon />*/}
        {/*    </span>*/}
        {/*  </span>*/}
        {/*</div>*/}
        {/*{!minimised && (*/}
        {/*  <>*/}
        {/*    <div className="h-[274px] min-w-full overflow-y-auto hide-scroll">*/}
        {/*      {messages.map((msg, index) => (*/}
        {/*        <div*/}
        {/*          key={index}*/}
        {/*          className={`flex w-full mt-4 space-x-3 max-w-${*/}
        {/*            msg.user_whosend === 'Wally' ? 'xs' : 'sm ml-auto justify-end'*/}
        {/*          } items-center`}*/}
        {/*        >*/}
        {/*          {msg.user_whosend === 'Wally' && (*/}
        {/*            <span className="relative flex gap-2 self-start pt-3">*/}
        {/*              <div className="avtar text-base font-bold text-lightblue">{msg.user_whosend}</div>*/}
        {/*            </span>*/}
        {/*          )}*/}
        {/*          <div className="mt-0">*/}
        {/*            <div*/}
        {/*              className={`p-3 rounded-${*/}
        {/*                msg.user_whosend === 'Wally'*/}
        {/*                  ? 'r-lg rounded-b-lg bg-[#0177b5]'*/}
        {/*                  : 'l-lg rounded-br-lg bg-lightblue'*/}
        {/*              }`}*/}
        {/*            >*/}
        {/*              <p className={`text-sm ${msg.user_whosend === 'Wally' ? 'text-white' : 'text-darkblue'}`}>*/}
        {/*                {msg.message}*/}
        {/*              </p>*/}
        {/*            </div>*/}
        {/*            /!* Uncomment the following line to show timestamps *!/*/}
        {/*            /!* <span className="text-xs text-gray-500 leading-none">{msg.timestamp}</span> *!/*/}
        {/*          </div>*/}
        {/*          {msg.user_whosend !== 'Wally' && (*/}
        {/*            <span className="relative flex shrink-0 rounded-full justify-center items-center self-start pt-3">*/}
        {/*              <div className="avtar text-base font-bold text-white pl-2">{msg.user_whosend}</div>*/}
        {/*            </span>*/}
        {/*          )}*/}
        {/*        </div>*/}
        {/*      ))}*/}
        {/*    </div>*/}
        {/*    <div className="flex items-center pt-0 mt-5">*/}
        {/*      <form className="flex items-center justify-center w-full space-x-2">*/}
        {/*        <input*/}
        {/*          className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"*/}
        {/*          placeholder="Type your message"*/}
        {/*          value={newMessage}*/}
        {/*          onChange={handleInputChange}*/}
        {/*        />*/}
        {/*        <button*/}
        {/*          className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white disabled:pointer-events-none disabled:opacity-50 bg-[#0177b5] hover:bg-[#111827E6] hover:text-white h-10 px-4 py-2"*/}
        {/*          onClick={handleSendMessage}*/}
        {/*        >*/}
        {/*          Send*/}
        {/*        </button>*/}
        {/*      </form>*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
    </>
  )
}

export default ChatAuction
