import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Orderprogress } from 'src/components/columns'
import { Swiper, SwiperSlide } from 'swiper/react'
import GreenTracingCardBuyer from 'src/components/auction/green-tracing-card-buyer'
import 'swiper/css/autoplay'
import { Autoplay } from 'swiper/modules'
import FilterComponent from 'src/components/common/filter'
import { useState } from 'react'
import StickyRightComponent from 'src/components/common/sticky-right'
import StatesFilter from 'src/components/states-filter'
import * as React from 'react'

const greenTracingWishlistJson = [
  { heading: 'Wind Power Bundled Project', image: '/images/image1.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image2.png', open: true },
  { heading: 'Solar Grouped Project', image: '/images/image3.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image4.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image5.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image6.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image7.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image8.png', open: true },
  { heading: 'Wind Farm Project', image: '/images/image1.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image2.png', open: true },
]

const greenTracingJson = [
  { heading: 'Wind Power Bundled Project', image: '/images/image1.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image2.png', open: false },
  { heading: 'Solar Grouped Project', image: '/images/image3.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image4.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image5.png', open: false },
  { heading: 'Geothermal Energy Project', image: '/images/image6.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image7.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image8.png', open: false },
  { heading: 'Wind Farm Project', image: '/images/image1.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image2.png', open: true },
]

async function getData(): Promise<Orderprogress[]> {
  // Fetch data from your API here.
  return [
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    // ...
  ]
}
const data = await getData()
export default function BuyerGreenTracingDashboard() {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  return (
    <>
      <section className="mt-5 mb-5 w-full">
        <div className="container mx-auto flex flex-row-reverse">
          <StatesFilter filterType="FUEL_TRADING" className="w-52" />
        </div>
      </section>
      {/* my watch list start */}
      <section className="mt-10 w-full">
        <div className="container mx-auto">
          <div className="mb-[15px] flex w-full justify-between">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">My Watchlist</h2>
            <a href="/green-tracing/my-watchlist" className="text-sm font-bold text-primary">
              View All
            </a>
          </div>
          <div className="">
            <Swiper
              // modules={[Pagination]}

              modules={[Autoplay]}
              loop={true}
              spaceBetween={20}
              slidesPerView={4}
              pagination={{
                clickable: false,
              }}
              autoplay={{
                delay: 2000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              grabCursor={true}
              breakpoints={{
                // when window width is <= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                // when window width is <= 480px
                425: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                // when window width is <= 640px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
              className="carousel mb-3 grid gap-5  xl:grid-cols-4 2xl:grid-cols-5"
            >
              {greenTracingWishlistJson.map((greenI, index) => {
                return (
                  <SwiperSlide key={index} className="relative mt-3">
                    <GreenTracingCardBuyer greencard={greenI} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </section>
      {/* All projects */}
      {/* my auction start */}
      <section className="mt-10 w-full">
        <div className="container mx-auto">
          <div className="relative mb-[25px] flex w-full items-center justify-between align-middle">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">All Projects</h2>
            <span className="relative text-sm">
              <div className="flex gap-[10px]">
                <button
                  onClick={() => setShowFilter(!showFilter)}
                  className="w-[102px] rounded-[10px] bg-white px-[15px] py-[8px] text-center align-middle text-xs font-medium text-secoundary shadow-md 2xl:text-sm"
                >
                  Filter By
                </button>
              </div>
            </span>
            {showFilter && (
              <FilterComponent
                // className="absolute right-0"
                onCancel={() => setShowFilter(false)}
              />
            )}
          </div>

          <div className="grid gap-5  xl:grid-cols-4 2xl:grid-cols-5">
            {greenTracingJson.map((greenI, index) => {
              return <GreenTracingCardBuyer key={index} greencard={greenI} />
            })}
          </div>
        </div>
      </section>
      <StickyRightComponent type="GREEN_TRACING" />
    </>
  )
}
