import { useTranslation } from 'react-i18next'
import React, { MouseEvent, useEffect, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { Input } from 'src/components/ui/input'
import ModalDocument from 'src/components/common/modalDocument'
import { z, ZodType } from 'zod'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import ErrorMessage from 'src/components/ui/error-message'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { ICompanyDoc, ICompanyDocMedia } from 'src/_models/company-doc.model'
import { useNavigate } from 'react-router-dom'
import { toast } from 'src/components/ui/use-toast'
import { useUserStore } from 'src/_store/user.store'
import { router } from 'src/router'

type IDocumentListGetForm = {
  countryId: number
  stateId: number
  city: string
}

type IState = {
  id: number
  name: string
}

export default function DocumentUpload({ goNext, goPrev }: { goNext: () => void; goPrev: () => void }) {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [states, setStates] = useState<IState[]>([])
  const [companyDocs, setCompanyDocs] = useState<ICompanyDoc[]>([])
  const [uploadCompanyDocsStep, setUploadCompanyDocsStep] = useState<boolean>(false)

  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  useEffect(() => {
    if (user?.step === 'COMPLETE') {
      navigate('/')
    }
  }, [user])

  const documentListZodType: ZodType<IDocumentListGetForm> = z.object({
    countryId: z.number({
      required_error: 'error.required',
    }),
    stateId: z.number({
      required_error: 'error.required',
    }),
    city: z.string({
      required_error: 'error.required',
    }),
  })

  const { control, handleSubmit } = useForm<IDocumentListGetForm>({
    resolver: zodResolver(documentListZodType),
  })

  const goBackMutation = useMutation({
    mutationFn: ({ step }: { step: string }) => {
      const token = localStorage.getItem('token')
      return axios.patch(
        `/api/auth/go-back`,
        { step },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const { isLoading: isLoadingCompanyDocs, data: dataCompanyDocs } = useQuery({
    queryKey: ['companyDocs'],
    queryFn: (): Promise<AxiosResponse<{ companyDocs: ICompanyDoc[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/company-docs', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const { isLoading, data } = useQuery({
    queryKey: ['countries'],
    queryFn: (): Promise<AxiosResponse<{ countries: { id: number; name: string }[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/countries', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const stateByCountryMutation = useMutation({
    mutationFn: (data: { countryId: string }): Promise<AxiosResponse<{ states: IState[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/states?countryId=' + data.countryId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const createDocumentsForStateAndCountry = useMutation({
    mutationFn: (data: IDocumentListGetForm): Promise<AxiosResponse<{ companyDocs: any[] }>> => {
      const token = localStorage.getItem('token')
      return axios.post('/api/company-docs', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (dataCompanyDocs?.data && dataCompanyDocs.data.companyDocs.length > 0) {
      setCompanyDocs(dataCompanyDocs.data.companyDocs)
      setUploadCompanyDocsStep(true)
    } else {
      setUploadCompanyDocsStep(false)
    }
  }, [dataCompanyDocs])

  const saveDocumentList = (data: IDocumentListGetForm) => {
    createDocumentsForStateAndCountry.mutate(data, {
      onSuccess: (resp) => {
        setCompanyDocs(resp.data.companyDocs)
        queryClient.invalidateQueries()
        setUploadCompanyDocsStep(true)
      },
      onError: (err) => {
        const axiosError = err as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: t(axiosError.response?.data.message || 'error.somethingWrong'),
        })
      },
    })
  }

  const goToAccountSetup = () => {
    goBackMutation.mutate(
      {
        step: 'ACCOUNT_SETUP',
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['profile'])
          goPrev()
        },
        onError: () => {
          goPrev()
        },
      },
    )
  }

  const completeUserSignUpStepsMutation = useMutation({
    mutationFn: (): Promise<AxiosResponse<{ message: string }>> => {
      const token = localStorage.getItem('token')
      return axios.post(
        '/api/auth/complete',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const completeUserSignUpSteps = () => {
    completeUserSignUpStepsMutation.mutate(
      // @ts-ignore
      {},
      {
        onSuccess: (resp) => {
          toast({
            title: 'Pending approval from Admin.',
            variant: 'destructive',
          })
          navigate('/auth/login')
          // queryClient.invalidateQueries()
        },
        onError: (err: AxiosError<{ message: string }>) => {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(err.response?.data?.message || 'error.somethingWrong'),
          })
        },
      },
    )
  }

  return (
    <>
      {uploadCompanyDocsStep ? (
        <>
          <div>
            <div className="mb-5 w-full">
              <h1 className="pb-[10px]  text-2xl font-bold text-txtblack">{t('documentUpload.heading')}</h1>
              <p className="text-sm font-normal text-txtblack">{t('documentUpload.subHeading')}</p>
            </div>
            {companyDocs?.map((companyDoc) => {
              return <UploadCompanyDocBtn key={companyDoc.id} companyDoc={companyDoc} />
            })}
          </div>
          <div className="flex gap-4 pb-5 pt-10">
            <Button type="submit" onClick={() => setUploadCompanyDocsStep(false)} variant="secondary">
              {t('back')}
            </Button>

            <Button
              type="submit"
              disabled={completeUserSignUpStepsMutation.isLoading}
              onClick={completeUserSignUpSteps}
            >
              {user?.userType === 'BUYER' ? t('signUpAsBuyer') : t('signUpAsSeller')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="mb-5 w-full">
              <h1 className="pb-[10px]  text-2xl font-bold text-txtblack">{t('documentUpload.heading')}</h1>
              <p className="text-sm font-normal text-txtblack">{t('documentUpload.subHeading')}</p>
            </div>

            <form className="pt-5" onSubmit={handleSubmit(saveDocumentList)}>
              <div className="pb-5">
                <label htmlFor="country" className="block text-sm font-bold leading-6 text-txtblack">
                  {t('documentUpload.countryLabel')}
                </label>
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="countryId"
                    render={({ field, fieldState }) => {
                      return (
                        <div className="mt-2">
                          <Select
                            name={field.name}
                            value={field.value ? field.value.toString() : ''}
                            onValueChange={(e) => {
                              if (!e) {
                                return
                              }
                              field.onChange(parseInt(e))
                              stateByCountryMutation.mutate(
                                {
                                  countryId: e,
                                },
                                {
                                  onSuccess: (resp) => {
                                    setStates(resp.data.states)
                                  },
                                },
                              )
                            }}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="" />
                            </SelectTrigger>
                            <SelectContent>
                              {data?.data.countries.map((country) => {
                                return (
                                  <SelectItem key={country.id} value={country.id.toString()}>
                                    {country.name}
                                  </SelectItem>
                                )
                              })}
                            </SelectContent>
                          </Select>
                          {fieldState.error?.message && (
                            <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                          )}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
              <div className="pb-5">
                <label htmlFor="state" className="block text-sm font-bold leading-6 text-txtblack">
                  {t('documentUpload.stateLabel')}
                </label>
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="stateId"
                    render={({ field, fieldState }) => {
                      return (
                        <div className="mt-2">
                          <Select
                            name={field.name}
                            value={field.value ? field.value.toString() : ''}
                            onValueChange={(e) => {
                              if (!e) {
                                return
                              }
                              field.onChange(parseInt(e))
                            }}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="" />
                            </SelectTrigger>
                            <SelectContent>
                              {states.map((state) => {
                                return (
                                  <SelectItem key={state.id} value={state.id.toString()}>
                                    {state.name}
                                  </SelectItem>
                                )
                              })}
                            </SelectContent>
                          </Select>
                          {fieldState.error?.message && (
                            <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                          )}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
              <div className="pb-5">
                <label htmlFor="city" className="block text-sm font-bold leading-6 text-txtblack">
                  {t('documentUpload.cityLabel')}
                </label>
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="city"
                    render={({ field, fieldState }) => {
                      return (
                        <Input
                          id="city"
                          type="text"
                          placeholder={t('documentUpload.cityLabel')}
                          {...field}
                          error={fieldState.error}
                        />
                      )
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="flex gap-4 pb-5 pt-10">
            <Button type="submit" onClick={goToAccountSetup} variant="secondary">
              {t('back')}
            </Button>

            <Button
              type="submit"
              disabled={createDocumentsForStateAndCountry.isLoading}
              onClick={handleSubmit(saveDocumentList)}
            >
              {t('next')}
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export const UploadCompanyDocBtn = ({ companyDoc }: { companyDoc: ICompanyDoc }) => {
  const { t } = useTranslation('translation')
  const queryClient = useQueryClient()
  const [showModalDocument, setShowModalDocument] = useState<boolean>(false)

  const uploadFileToCompanyDoc = useMutation({
    mutationFn: (data: { file: File }): Promise<AxiosResponse<{ companyDocs: any[] }>> => {
      const formData = new FormData()
      formData.append('file', data.file)
      const token = localStorage.getItem('token')
      return axios.post('/api/company-docs/' + companyDoc.id, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const onFileSelection = (file: File) => {
    setShowModalDocument(false)
    uploadFileToCompanyDoc.mutate(
      { file: file },
      {
        onSuccess: (resp) => {
          toast({
            title: 'File uploaded.',
          })
          queryClient.invalidateQueries({
            queryKey: ['companyDocs'],
          })
        },
        onError: (err) => {
          const error = err as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(error.response?.data?.message || error.message || 'err.somethingWrong'),
          })
        },
      },
    )
  }

  const [filesShown, setFilesShown] = useState<boolean>(false)

  return (
    <>
      <div key={companyDoc.id} className="grid grid-cols-12 items-center gap-2">
        <div className="col-span-9 flex flex-col">
          <div className="w-full">{companyDoc.docName}</div>
          {companyDoc.required && companyDoc.companyDocMedia && companyDoc.companyDocMedia.length === 0 && (
            <ErrorMessage message={t('error.required')} />
          )}
          {/*{companyDoc.mediaId && (*/}
          {/*  <div className="w-full">*/}
          {/*    <a className="cursor-pointer text-primary" onClick={() => downloadUploadedFile(companyDoc)}>*/}
          {/*      View File*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
        <div className="col-span-3 flex items-center justify-end">
          {companyDoc.companyDocMedia && companyDoc.companyDocMedia.length > 0 && (
            <span className="cursor-pointer" onClick={() => setFilesShown((filesShown) => !filesShown)}>
              {filesShown ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7 10L12 15L17 10H7Z" fill="#333333" />
                </svg>
              ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10H7Z" fill="#333333" />
                </svg>
              )}
            </span>
          )}
          <Button
            disabled={uploadFileToCompanyDoc.isLoading}
            expand="normal"
            className="ml-2.5 px-3"
            onClick={() => setShowModalDocument(true)}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.5 20C4.98 20 3.68333 19.4767 2.61 18.43C1.53667 17.3767 1 16.0933 1 14.58C1 13.28 1.39 12.12 2.17 11.1C2.95667 10.08 3.98333 9.43 5.25 9.15C5.67 7.61667 6.50333 6.37667 7.75 5.43C9.00333 4.47667 10.42 4 12 4C13.9533 4 15.6067 4.68 16.96 6.04C18.32 7.39333 19 9.04667 19 11C20.1533 11.1333 21.1067 11.6333 21.86 12.5C22.62 13.3533 23 14.3533 23 15.5C23 16.7533 22.5633 17.8167 21.69 18.69C20.8167 19.5633 19.7533 20 18.5 20H13C12.4533 20 11.9833 19.8033 11.59 19.41C11.1967 19.0233 11 18.5533 11 18V12.85L9.4 14.4L8 13L12 9L16 13L14.6 14.4L13 12.85V18H18.5C19.2 18 19.79 17.7567 20.27 17.27C20.7567 16.79 21 16.2 21 15.5C21 14.8 20.7567 14.21 20.27 13.73C19.79 13.2433 19.2 13 18.5 13H17V11C17 9.62 16.5133 8.44 15.54 7.46C14.5667 6.48667 13.3867 6 12 6C10.62 6 9.44 6.48667 8.46 7.46C7.48667 8.44 7 9.62 7 11H6.5C5.53333 11 4.71 11.3433 4.03 12.03C3.34333 12.71 3 13.5333 3 14.5C3 15.4667 3.34333 16.3 4.03 17C4.71 17.6667 5.53333 18 6.5 18H9V20"
                fill="#F2F2F2"
              />
            </svg>
          </Button>
        </div>
      </div>
      <div className="mb-5 mt-2.5">
        {filesShown &&
          companyDoc.companyDocMedia.map((docMedia) => <CompanyFileListItem docMedia={docMedia} key={docMedia.id} />)}
      </div>
      {showModalDocument && (
        <ModalDocument onCancel={() => setShowModalDocument(false)} onFileSelection={onFileSelection} />
      )}
    </>
  )
}

export const CompanyFileListItem = ({ docMedia }: { docMedia: ICompanyDocMedia }) => {
  const { t } = useTranslation('translation')
  const queryClient = useQueryClient()

  const downloadUploadedFileMutation = useMutation({
    mutationFn: (data: { id: number }): Promise<AxiosResponse<Blob>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/company-docs/' + data.id, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const deleteUploadedFileMutation = useMutation({
    mutationFn: (data: { id: number }): Promise<AxiosResponse<Blob>> => {
      const token = localStorage.getItem('token')
      return axios.delete('/api/company-docs/' + data.id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const downloadUploadedFile = () => {
    toast({
      title: 'File download starting now.',
    })
    downloadUploadedFileMutation.mutate(
      { id: docMedia.id },
      {
        onSuccess: (resp) => {
          // saveAs(resp.data)
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', docMedia.media?.fileName || docMedia.media?.fileKey || 'file') //or any other extension
          document.body.appendChild(link)
          link.click()
          toast({
            title: 'File downloaded',
          })
        },
        onError: (err) => {
          const error = err as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(error.response?.data?.message || error.message || 'err.somethingWrong'),
          })
        },
      },
    )
  }

  const deleteUploadedFile = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    deleteUploadedFileMutation.mutate(
      { id: docMedia.id },
      {
        onSuccess: (resp) => {
          queryClient.invalidateQueries({
            queryKey: ['companyDocs'],
          })
          toast({
            title: 'File deleted',
          })
        },
        onError: (err) => {
          toast({
            title: 'File deletion failed',
          })
        },
      },
    )
  }

  return (
    <div
      onClick={downloadUploadedFile}
      key={docMedia.id}
      className="mb-2.5 inline-flex h-[50px] w-[407px] items-center justify-between rounded-[10px] bg-white p-2.5 shadow"
    >
      <div className="flex cursor-pointer items-center justify-start gap-2.5">
        <div className="relative h-[30px] w-[30px]">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.125 11.875H22.3125L16.125 5.6875V11.875ZM8.25 4H17.25L24 10.75V24.25C24 24.8467 23.7629 25.419 23.341 25.841C22.919 26.2629 22.3467 26.5 21.75 26.5H8.25C7.00125 26.5 6 25.4875 6 24.25V6.25C6 5.00125 7.00125 4 8.25 4ZM18.375 22V19.75H8.25V22H18.375ZM21.75 17.5V15.25H8.25V17.5H21.75Z"
              fill="#333333"
            />
          </svg>
        </div>
        <div className="font-['Montserrat'] text-sm font-normal tracking-tight text-zinc-800">{docMedia.fileName}</div>
      </div>
      <div className="relative h-6 w-6 cursor-pointer" onClick={deleteUploadedFile}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
            fill="#333333"
          />
        </svg>
      </div>
    </div>
  )
}
