import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalBox from 'src/components/common/modal'
import { z, ZodType } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Input } from 'src/components/ui/input'
import { InputPassword } from 'src/components/ui/password-input'
import { Button } from 'src/components/ui/button'
import { redirect, useNavigate } from 'react-router-dom'
import { toast } from 'src/components/ui/use-toast'
import mixpanel from 'mixpanel-browser'

type ILoginForm = {
  email: string
  password: string
}

export default function LoginPage() {
  const { t } = useTranslation('translation')
  const [showModalBox, setShowModalBox] = useState(false)
  const navigate = useNavigate()
  const showModal = (value: boolean) => {
    setShowModalBox(value)
  }

  const LoginSchema: ZodType<ILoginForm> = z.object({
    email: z
      .string({ required_error: t('error.emailRequired') })
      .min(1, t('error.emailRequired'))
      .email(t('error.emailInvalid')),
    password: z.string({ required_error: t('error.passwordRequired') }).min(1, t('error.passwordRequired')),
  })

  const { control, handleSubmit } = useForm<ILoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(LoginSchema),
  })

  const loginMutation = useMutation({
    mutationFn: (
      data: ILoginForm,
    ): Promise<AxiosResponse<{ token: string; verificationRequired: boolean; step: string }>> => {
      return axios.post('/api/auth/login', data)
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: ['todos'] })
    // },
  })

  const login = (data: ILoginForm) => {
    loginMutation.mutate(data, {
      onSuccess: (resp) => {
        localStorage.setItem('token', resp.data?.token)
        if (resp.data?.verificationRequired) {
          toast({ title: 'Verification required.' })
          navigate(`/auth/signup`)
          return
        }
        if (resp.data?.step === 'ACCOUNT_SETUP' || resp.data?.step === 'DOCUMENT_UPLOAD') {
          toast({ title: 'Please complete sign up process.' })
          resp.data?.step === 'ACCOUNT_SETUP' ? navigate('/auth/signup?step=2') : navigate('/auth/signup?step=3')
          return
        }
        toast({ title: t('Successful login.') })
        navigate('/')
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string }>
        mixpanel.track('Login Error', {
          error: error.response?.data?.message,
        })
        if (error.response?.status === 401) {
          toast({
            variant: 'destructive',
            title: error.response?.data?.message || t('login.incorrectCredentialError'),
          })
        } else {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(error.response?.data?.message || 'error.somethingWrong'),
          })
        }
      },
    })
  }

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center pl-[62px] pr-[83px]">
      <div className="w-full rounded-[20px] bg-white px-10 py-[60px]">
        <h1 className="pb-10 text-2xl font-bold text-txtblack">{t('login.loginNow')}</h1>

        <form className="" onSubmit={handleSubmit(login)} noValidate={true}>
          <div className="pb-5">
            <label htmlFor="email" className="block text-sm font-bold leading-6 text-txtblack">
              {t('login.emailLabel')}
            </label>

            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => {
                return (
                  <div className="mt-2">
                    <Input
                      id="email"
                      type="email"
                      autoComplete="email"
                      placeholder={t('login.emailPlaceholder')}
                      error={fieldState.error}
                      {...field}
                    />
                  </div>
                )
              }}
            />
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-bold leading-6">
                {t('login.passwordLabel')}
              </label>
            </div>
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState }) => {
                return (
                  <div className="mt-2">
                    <InputPassword id="password" error={fieldState.error} {...field} />
                  </div>
                )
              }}
            />
          </div>

          <div className="pt-10 text-blue-500">
            <a href="/auth/forget-password">Forget Password?</a>
          </div>

          <div className="flex gap-4 pt-10">
            <Button type="button" expand="full" onClick={() => showModal(true)}>
              {t('login.signUpBtn')}
            </Button>
            <Button disabled={loginMutation.isLoading} expand="full" type="submit">
              {t('login.loginBtn')}
            </Button>
          </div>
        </form>
      </div>
      {showModalBox && <ModalBox onCancel={() => showModal(false)} />}
    </div>
  )
}
