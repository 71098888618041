import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { DataTable } from 'src/components/data-table'
import {
  Orderprogress,
  columns,
  columnsRECTransactionHistory,
  ColumnsRECTransactionHistory,
} from 'src/components/columns'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Swiper, SwiperSlide } from 'swiper/react'
import RecTradingCardBuyer from 'src/components/auction/rec-trading-card-buyer'
import 'swiper/css/autoplay'
import { Autoplay } from 'swiper/modules'
import StickyRightComponent from 'src/components/common/sticky-right'
import StatesFilter from 'src/components/states-filter'
import * as React from 'react'

const recTracingwatchlistJson = [
  { heading: 'Wind Power Bundled Project', image: '/images/image1.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image2.png', open: true },
  { heading: 'Solar Grouped Project', image: '/images/image3.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image4.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image5.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image6.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image7.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image8.png', open: true },
  { heading: 'Wind Farm Project', image: '/images/image1.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image2.png', open: true },
]
const recTracingJson = [
  { heading: 'Wind Power Bundled Project', image: '/images/image1.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image2.png', open: false },
  { heading: 'Solar Grouped Project', image: '/images/image3.png', open: false },
  { heading: 'Hydroelectric Power Project', image: '/images/image4.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image5.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image6.png', open: false },
  { heading: 'Hydroelectric Power Project', image: '/images/image7.png', open: false },
  { heading: 'Geothermal Energy Project', image: '/images/image8.png', open: false },
  { heading: 'Wind Farm Project', image: '/images/image1.png', open: false },
  { heading: 'Hydroelectric Power Project', image: '/images/image2.png', open: true },
]
ChartJS.register(ArcElement, Tooltip, Legend)
const dataChart = {
  labels: ['Wind', 'Solar', 'Hydroelectric', 'Geothermal', 'Bio-fuels'],
  datasets: [
    {
      label: '# of Renewable Source ',
      data: [38, 19, 12, 23, 14],
      backgroundColor: ['#76C2F6', '#46DE69', '#90E940', '#FFC26A', '#EA9184'],
      // borderColor: [
      //   'rgba(255, 99, 132, 1)',
      //   'rgba(54, 162, 235, 1)',
      //   'rgba(255, 206, 86, 1)',
      //   'rgba(75, 192, 192, 1)',
      //   'rgba(153, 102, 255, 1)',
      //   'rgba(255, 159, 64, 1)',
      // ],
      borderWidth: 0,
    },
  ],

  hoverOffset: 4,
}
const options = {
  plugins: {
    legend: {
      position: 'right',
      align: 'start',
      labels: {
        usePointStyle: true,
      },
      // title: {
      //   display: true,
      //   text: 'Bar Chart',
      // },
    },
  },

  maintainAspectRatio: false, // Set to false to allow specifying the width and height directly
  aspectRatio: 1, // Aspect ratio (width/height) of the chart. Adjust this value to change the width
}
async function getOrderData(): Promise<Orderprogress[]> {
  // Fetch data from your API here.
  return [
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    // ...
  ]
}
async function getTransactionData(): Promise<ColumnsRECTransactionHistory[]> {
  // api call to get data
  return [
    {
      id: '1',
      transaction_date: '2022-01-01',
      rec_type: 'Additionality',
      quantity: '1000',
      transaction_id: 'AS091238',
      project_name: 'Solar Power',
      amount: '$10000.00',
      action: 'completed',
    },
    {
      id: '2',
      transaction_date: '2022-01-02',
      rec_type: 'Additionality',
      quantity: '5000',
      transaction_id: 'AW901236',
      project_name: 'Wind Project',
      amount: '$200000.00',
      action: 'completed',
    },
    {
      id: '3',
      transaction_date: '2022-01-03',
      rec_type: 'Additionality',
      quantity: '1000',
      transaction_id: 'AW012920',
      project_name: 'Wind Power',
      amount: '$3000.00',
      action: 'completed',
    },
    {
      id: '4',
      transaction_date: '2022-01-04',
      rec_type: 'Additionality',
      quantity: '1000',
      transaction_id: 'AH812732',
      project_name: 'Hydro Power',
      amount: '$40000.00',
      action: 'completed',
    },
  ]
}
const data = await getOrderData()
const data_rec_transaction = await getTransactionData()
export default function BuyerRecTradingDashboard() {
  return (
    <>
      <section className="mt-5 mb-5 w-full">
        <div className="container mx-auto flex flex-row-reverse">
          <StatesFilter filterType="FUEL_TRADING" className="w-52" />
        </div>
      </section>
      {/* my statistics start */}
      <section className="mt-5 w-full">
        <div className="container mx-auto">
          <div className="mb-[15px] flex w-full justify-between">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">My Statistics</h2>
            {/* <a href="#" className="text-sm font-bold text-primary">
              View All
            </a> */}
          </div>

          <div className="-mb-5 -ml-5 -mr-5 h-full w-full p-0">
            <img src="/graph_image.svg" style={{ width: '100%', display: 'block' }} />
          </div>
        </div>
      </section>
      {/* My statistics  end */}
      {/* my watchlist start */}
      <section className="mt-10 w-full">
        <div className="container mx-auto">
          <div className="mb-[15px] flex w-full items-center justify-between align-middle">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">My Watchlist</h2>
            <a href="/rec-trading/my-watchlist" className="text-sm font-bold text-primary">
              View All
            </a>
          </div>
          <div className="">
            <Swiper
              // modules={[Pagination]}
              modules={[Autoplay]}
              loop={true}
              spaceBetween={20}
              slidesPerView={4}
              autoplay={{
                delay: 2000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              pagination={{
                clickable: false,
              }}
              grabCursor={true}
              breakpoints={{
                // when window width is <= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                // when window width is <= 480px
                425: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                // when window width is <= 640px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
              className="carousel mb-3 grid gap-5 xl:grid-cols-4 2xl:grid-cols-5"
            >
              {recTracingwatchlistJson.map((recI, index) => {
                return (
                  <SwiperSlide key={index} className="relative mt-3">
                    <RecTradingCardBuyer reccard={recI} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </section>

      {/* Live REC Projects */}
      <section className="mt-10 w-full">
        <div className="container mx-auto">
          <div className="mb-[15px]  flex w-full items-center justify-between align-middle">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">Live REC Projects</h2>
            <a href="/rec-trading/live-rec-projects" className="text-sm font-bold text-primary">
              View All
            </a>
          </div>
          <div className="">
            <Swiper
              // modules={[Pagination]}
              modules={[Autoplay]}
              loop={true}
              spaceBetween={20}
              slidesPerView={4}
              autoplay={{
                delay: 2200,
                stopOnLastSlide: false,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              pagination={{
                clickable: false,
              }}
              grabCursor={true}
              breakpoints={{
                // when window width is <= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                // when window width is <= 480px
                425: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                // when window width is <= 640px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
              className="carousel grid gap-5  xl:grid-cols-4 2xl:grid-cols-5"
            >
              {recTracingJson.map((recI, index) => {
                return (
                  <SwiperSlide key={index} className="relative mt-3">
                    <RecTradingCardBuyer reccard={recI} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </section>
      {/* Projects Previously Purchased From */}

      <section className="mt-14 w-full">
        <div className="container mx-auto">
          <div className="mb-[15px] flex w-full justify-between align-middle">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">Projects Previously Purchased From</h2>
            <a href="/rec-trading/project-prev-purchased" className="text-sm font-bold text-primary">
              View All
            </a>
          </div>

          <div className="">
            <Swiper
              // modules={[Pagination]}

              loop={false}
              spaceBetween={20}
              slidesPerView={4}
              pagination={{
                clickable: false,
              }}
              autoplay={{
                pauseOnMouseEnter: true,
              }}
              grabCursor={true}
              breakpoints={{
                // when window width is <= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                // when window width is <= 480px
                425: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                // when window width is <= 640px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
              className="carousel grid gap-5  xl:grid-cols-4 2xl:grid-cols-5"
            >
              {recTracingJson.map((recI, index) => {
                return (
                  <SwiperSlide key={index} className="relative mt-3">
                    <RecTradingCardBuyer reccard={recI} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </section>
      {/* Pop UP Step 1*/}
      {/* <div className="mx-auto h-full w-[680] max-w-3xl overflow-y-auto rounded-[20px] bg-white px-10 py-10">
        <form>
          <div className="flex w-full gap-[15px]">
            <div className="rounded-base flex w-[200px] flex-col items-center justify-center bg-gray2 text-center"></div>
            <div className="w-full">
              <div className="flex w-full flex-col gap-[2px]">
                <h6>Solar Photovoltaic Power Project</h6>
                <label
                  htmlFor="action"
                  className="block flex items-start justify-between gap-[10px] text-lg font-[700] font-medium leading-6 text-[#333] 2xl:text-xl"
                >
                  <div>
                    <span className="line-clamp-1">Solar Power ARECs</span>
                    <p className="mt-[8px] flex gap-2 text-sm font-normal   2xl:text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="23" viewBox="0 0 17 23" fill="none">
                        <rect width="17" height="23" fill="#1E1E1E" />
                        <g id="Live Purchase now Hover">
                          <rect x="-258" y="-183" width="719" height="630" rx="34" fill="white" />
                          <rect x="-258" y="-183" width="719" height="630" rx="34" stroke="white" strokeWidth="12" />
                          <g id="Frame 5">
                            <g id="Frame 1000002615">
                              <g id="Frame 1000002612">
                                <g id="Frame 33">
                                  <g id="Frame 1000002620">
                                    <path
                                      id="Vector"
                                      d="M8.38095 2.59524C10.6857 2.59524 12.5714 4.48095 12.5714 6.78571C12.5714 8.98571 10.3714 12.5476 8.38095 15.0619C6.39048 12.4429 4.19048 8.98571 4.19048 6.78571C4.19048 4.48095 6.07619 2.59524 8.38095 2.59524ZM8.38095 0.5C4.92381 0.5 2.09524 3.32857 2.09524 6.78571C2.09524 11.5 8.38095 18.3095 8.38095 18.3095C8.38095 18.3095 14.6667 11.3952 14.6667 6.78571C14.6667 3.32857 11.8381 0.5 8.38095 0.5ZM8.38095 4.69048C7.22857 4.69048 6.28571 5.63333 6.28571 6.78571C6.28571 7.9381 7.22857 8.88095 8.38095 8.88095C9.53333 8.88095 10.4762 7.9381 10.4762 6.78571C10.4762 5.63333 9.53333 4.69048 8.38095 4.69048ZM16.7619 18.3095C16.7619 20.6143 12.9905 22.5 8.38095 22.5C3.77143 22.5 0 20.6143 0 18.3095C0 16.9476 1.25714 15.7952 3.24762 14.9571L3.87619 15.9C2.82857 16.4238 2.09524 17.0524 2.09524 17.7857C2.09524 19.2524 4.92381 20.4048 8.38095 20.4048C11.8381 20.4048 14.6667 19.2524 14.6667 17.7857C14.6667 17.0524 13.9333 16.4238 12.781 15.9L13.4095 14.9571C15.5048 15.7952 16.7619 16.9476 16.7619 18.3095Z"
                                      fill="#333333"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span className=" line-clamp-1">4894 Gooch Hill Rd, San Jose, CA 94088</span>
                    </p>
                  </div>
                  <span className="flex w-[100px] flex-none flex-shrink-0 flex-grow-0 rounded-[10px] border-[2px] border-[#E0E0E0] px-2 py-[4px] text-center text-sm font-normal">
                    Since 2010
                  </span>
                </label>

                <div className="descripton  mt-[10px]">
                  <p className="line-clamp-2 text-sm">
                    The Fuentes solar farm is a privately owned and feeds its power into the PG&E utility and grid
                    system. It has been approved by the Californian Energy Commission, Californian Public Utilities
                    Commission & California ISO to sell Additionality RECs.
                  </p>
                </div>
                <div className="flex w-full gap-2 rounded-lg pt-2 text-[#006E9D]">
                  <span className="font-base text-sm">*Tracked by ERCOT </span>
                </div>
                <div className="flex w-full gap-2 rounded-lg text-[#006E9D]">
                  <span className="font-base text-sm">*With REgeneration since 2023 </span>
                </div>
              </div>
            </div>
          </div>

          <hr className=" mt-4 h-px border-0 bg-[#E0E0E0]" />
          <div className="flex w-full flex-col rounded-lg py-5">
            <div className="flex flex-col">
              <div className="mb-[10px] flex w-full items-center justify-between font-[500]">
                <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">Energy Produced per month</p>
                <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">7200 MWh</p>
              </div>
              <div className="mb-[10px] flex w-full items-center justify-between">
                <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">Energy Produced per hr</p>
                <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">74 MWh</p>
              </div>
              <div className="mb-[10px] flex w-full items-center justify-between">
                <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">Total Energy Produced</p>
                <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">49,003 MWh</p>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col rounded-lg py-2">
            <h5 className="flex w-full py-2 text-base font-bold text-[#333]">Verified Documents</h5>
            <ul className="">
              <li className="py-[4px] flex gap-2"><img src='/public//bullet.png' alt=''  />Renewable Energy Power Purchase Agreement</li>
              <li className="py-[4px] flex gap-2"><img src='/public//bullet.png' alt=''  />Real-time connection to power plant SCADA system</li>
              <li className="py-[4px] flex gap-2"><img src='/public//bullet.png' alt=''  />Digital Renewable Energy Certificate generation</li>
              <li className="py-[4px] flex gap-2"><img src='/public//bullet.png' alt=''  />Project Validation Report</li>
              <li className="py-[4px] flex gap-2"><img src='/public//bullet.png' alt=''  />REC Issuance Report</li>
            </ul>
          </div>
        </form>
        <div className="flex w-full gap-2 rounded-lg py-2 text-[#006E9D]">
          <span className="font-base font-bold underline">View Project Registry Details</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 15 15"
            fill="none"
            className="relative top-2"
          >
            <path
              d="M0.105469 13.1955L1.51547 14.6055L12.1055 4.01547L12.1055 10.6055L14.1055 10.6055L14.1055 0.605469L4.10547 0.605468L4.10547 2.60547L10.6955 2.60547L0.105469 13.1955Z"
              fill="#006E9D"
            />
          </svg>
        </div>
        <div className="mt-5 flex gap-5">
          <Button>Buy Bundled Energy</Button>
        </div>
      </div> */}
      {/* Pop UP Step 1 End*/}
      {/* Pop UP Step 2*/}
      {/* <div className="mx-auto h-full w-[660px] max-w-4xl overflow-y-auto rounded-[20px] bg-white px-5 py-5">
        <div className="">
          <table className="w-full border-collapse border-spacing-2 text-left text-sm rtl:text-right">
            <thead className="text-xs">
              <tr>
                <th scope="col" className="w-1/4 py-3 text-left">
                  Vintage Date
                </th>
                <th scope="col" className="w-1/4 py-3">
                  Available RECs
                </th>
                <th scope="col" className="py-3">
                  Price/REC
                </th>

                <th scope="col" className="w-1/4 py-3">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Button className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]">
                    <span>Purchase Now</span>
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Button className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]">
                    <span>Purchase Now</span>
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Button className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]">
                    <span>Purchase Now</span>
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Button className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]">
                    <span>Purchase Now</span>
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Button className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]">
                    <span>Purchase Now</span>
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      {/* Pop UP Step 2 End*/}
      {/* Pop UP Step 3*/}
      {/* <div className="mx-auto h-full w-[520px] max-w-2xl overflow-y-auto rounded-[40px] bg-white px-10 py-5">
        <div className="">
          <p className="py-4 text-center">Enter how many RECs do you want to purchase?</p>
          <p className="flex justify-center py-5 text-center text-sm font-bold text-secoundary 2xl:text-base">
            <Input
              id="recpurchase"
              autoComplete="REC Purchase"
              placeholder="REC Purchase"
              name="recurchase"
              value="4400.00"
              spellCheck="false"
              data-ms-editor="false"
              className="w-1/2 bg-white text-center "
            />
          </p>
          <div className="mt-5 flex gap-5">
            <Button variant="secondary">Back</Button>
            <Button>Buy Now</Button>
          </div>
        </div>
      </div> */}
      {/* Pop UP Step 2 End*/}
      {/* My auction  end */}

      {/* My auction  end */}
      <section className="my-10 w-full">
        <div className="container mx-auto flex gap-8">
          <div className="w-1/2">
            <div className="mb-[15px] flex w-full items-center justify-between">
              <h2 className="pr-5 text-lg font-bold text-secoundary">Completed Orders</h2>
              <a href="/rec-trading/orders-in-progress/" className="text-sm font-bold text-primary">
                View All
              </a>
            </div>

            <DataTable columns={columns} data={data} headerHidden={true} />
          </div>
          <div className="w-1/2">
            <div className="mb-[15px] flex w-full items-center justify-between">
              <h2 className="text-lg font-bold text-secoundary">Transaction History</h2>
              <a href="/rec-trading/order-completed/" className="pr-5 text-sm font-bold text-primary">
                View All
              </a>
            </div>
            <DataTable columns={columnsRECTransactionHistory} data={data_rec_transaction} headerHidden={true} />
          </div>
        </div>
      </section>
      <StickyRightComponent type="REC" />
    </>
  )
}
